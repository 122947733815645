import React from 'react';
import Navigation from '../components/Navigation';
import useWindowSizeListener from '../helpers/useWindowSizeListener';
import emotionReset from 'emotion-reset';
import { Global, css } from '@emotion/react';
import '@fontsource/barlow';
import { RM } from '@request-metrics/browser-agent';

RM.install({
  token: 'k7rg7ca:p4zm3mu',
});

export const WindowSizeContext = React.createContext();

const MainLayout = (props) => {
  const windowSize = useWindowSizeListener();

  return (
    <div>
      <Global
        styles={css`
          ${emotionReset}
          body {
            font-family: 'Barlow', sans-serif;
            color: #fff;
          }

          *,
          *::after,
          *::before {
            box-sizing: border-box;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;
          }
        `}
      />
      <WindowSizeContext.Provider value={windowSize}>
        <Navigation />
        {React.cloneElement(props.children, { className: 'top bottom' })}
      </WindowSizeContext.Provider>
    </div>
  );
};

export default MainLayout;
