import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/navigation.scss';
import useScrollListener from '../helpers/useScrollListener';
import { useLocation } from '@reach/router';
import { WindowSizeContext } from '../layouts';
import arrow1 from '../images/arrowRight.svg';
import arrow2 from '../images/arrowRight.svg';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';

gsap.registerPlugin(ScrollToPlugin);

const Navigation = () => {
  const [navClassList, setNavClassList] = useState(['navigation']);
  const [navClassListMobile, setNavClassListMobile] = useState([
    'menu-link-wrapper',
  ]);
  const [activeLink, setActiveLink] = useState('');

  const [mobileMenu, setMobileMenu] = useState({
    active: false,
    open: false,
  });
  const [isBackTopVisible, setisBackTopVisible] = useState(false);

  const scroll = useScrollListener();
  const location = useLocation();
  const windowSize = React.useContext(WindowSizeContext);

  useEffect(() => {
    if (windowSize <= 768) {
      if (
        scroll.y < 200 ||
        scroll.y + window.innerHeight === document.documentElement.scrollHeight
      ) {
        setisBackTopVisible(false);
        return;
      }

      setisBackTopVisible(true);
      return;
    } else if (scroll.y < 200) {
      setisBackTopVisible(false);
    } else setisBackTopVisible(true);
  }, [scroll.y, windowSize]);

  useEffect(() => {
    const classList = ['navigation box-shadow'];
    const classListMobile = ['menu-link-wrapper'];

    if (scroll.y - scroll.lastY > 0 && scroll.y > 0) classList.push('hide');
    if (scroll.y > 150) {
      classList.push('scroll');
      classListMobile.push('scroll-mobile');
    }

    if (windowSize <= 768) classList.push('overflowMenu');
    if (mobileMenu.active) classList.push('showMobile');
    if (location.pathname === '/') classList.push('home');

    setNavClassList(classList);
    setNavClassListMobile(classListMobile);
  }, [
    scroll.y,
    scroll.lastY,
    windowSize,
    mobileMenu.active,
    location.pathname,
  ]);

  useEffect(() => {
    let path = location.pathname;
    if (location.hash) {
      path = location.hash;
    }
    const formatPath = path.replace(/[\/#]/g, '');
    setActiveLink(formatPath);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenu((prev) => {
      return {
        ...prev,
        active: !prev.active,
      };
    });
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    toggleMobileMenu();
  };

  const handleMobileMenu = (section) => {
    if (!section) {
      setActiveLink('');
      toggleMobileMenu();
      return;
    }
    setActiveLink(section);

    toggleMobileMenu();
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: `#${section}`,
        offsetY: windowSize <= 768 ? 10 : 100,
      },
    });
  };

  const scrollToTop = () => {
    gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
  };

  return (
    <>
      <div onClick={toggleMobileMenu} className={navClassListMobile.join(' ')}>
        <div
          className={
            mobileMenu.active ? 'menu-link menu-trigger-open' : 'menu-link'
          }
        >
          <span className='lines'></span>
        </div>
      </div>
      <div className={mobileMenu.active ? 'overflowMenu' : null}>
        <nav className={navClassList.join(' ')}>
          <div className='container'>
            <div className='menu'>
              {location.pathname !== '/' ? (
                <ul>
                  <li onClick={() => handleLinkClick('')}>
                    <Link className={activeLink === '' ? 'active' : ''} to='/'>
                      <span>O mnie</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('kalistenika')}>
                    <Link
                      className={activeLink === 'kalistenika' ? 'active' : ''}
                      to='/#kalistenika'
                    >
                      <span>Kalistenika</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('wolne-ciezary')}>
                    <Link
                      className={activeLink === 'wolne-ciezary' ? 'active' : ''}
                      to='/#wolne-ciezary'
                    >
                      <span>WOLNE CIĘŻARY</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('dietetyka')}>
                    <Link
                      className={activeLink === 'dietetyka' ? 'active' : ''}
                      to='/#dietetyka'
                    >
                      <span>dietetyka</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('trening domatora')}>
                    <Link
                      className={
                        activeLink === 'trening domatora' ? 'active' : ''
                      }
                      to='/#trening-domatora'
                    >
                      <span>trening domatora</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('oferta')}>
                    <Link
                      className={activeLink === 'oferta' ? 'active' : ''}
                      to='/oferta'
                    >
                      <span>Oferta</span>
                    </Link>
                  </li>
                  <li onClick={() => handleLinkClick('kontakt')}>
                    <Link
                      className={activeLink === 'kontakt' ? 'active' : ''}
                      to='/kontakt'
                    >
                      <span>Kontakt</span>
                    </Link>
                  </li>
                  <li className='socials'>
                    <a
                      aria-label='facebook'
                      href='https://www.facebook.com/krystian.chwacinski'
                    >
                      <img alt='' className='fb' src={facebook}></img>
                    </a>
                    <a
                      aria-label='instagram'
                      href='https://instagram.com/krystian.trener'
                    >
                      <img alt='' className='inst' src={instagram}></img>
                    </a>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li onClick={() => handleMobileMenu('')}>
                    <Link className={activeLink === '' ? 'active' : ''} to='/'>
                      <span>O mnie</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('kalistenika')}>
                    <Link
                      to='/#kalistenika'
                      className={activeLink === 'kalistenika' ? 'active' : ''}
                    >
                      <span>Kalistenika</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('wolne-ciezary')}>
                    <Link
                      to='/#wolne-ciezary'
                      className={activeLink === 'wolne-ciezary' ? 'active' : ''}
                    >
                      <span>WOLNE CIĘŻARY</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('dietetyka')}>
                    <Link
                      className={activeLink === 'dietetyka' ? 'active' : ''}
                      to='/#dietetyka'
                    >
                      <span>dietetyka</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('trening-domatora')}>
                    <Link
                      className={
                        activeLink === 'trening-domatora' ? 'active' : ''
                      }
                      to='/#trening-domatora'
                    >
                      <span>trening domatora</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('oferta')}>
                    <Link
                      className={activeLink === 'oferta' ? 'active' : ''}
                      to='/oferta'
                    >
                      <span>Oferta</span>
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('kontakt')}>
                    <Link
                      className={activeLink === 'kontakt' ? 'active' : ''}
                      to='/kontakt'
                    >
                      <span>Kontakt</span>
                    </Link>
                  </li>
                  <li className='socials'>
                    <a
                      aria-label='facebook'
                      href='https://www.facebook.com/krystian.chwacinski'
                    >
                      <img alt='' className='fb' src={facebook}></img>
                    </a>
                    <a
                      aria-label='instagram'
                      href='https://instagram.com/krystian.trener'
                    >
                      <img alt='' className='inst' src={instagram}></img>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </div>
      {isBackTopVisible ? (
        <div onClick={() => scrollToTop()} className='scroll-top'>
          <div className='arrows'>
            <img className='arrow1' src={arrow1} alt='' />
            <img className='arrow2' src={arrow2} alt='' />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navigation;
